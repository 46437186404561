.c-quotebox {
    position: absolute;
    left: -40px;
    top: 30%;
    right: auto;
    bottom: auto;
    width: 400px;
    padding: 96px 64px 32px;
    background-color: #fff;
}

.display-flex{
    display: flex;
}

.display-none{
    display: none;
}

.navigation-and-offcanvas{
    transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.display-block-opacity-1{
    display: block; 
    opacity: 1;
}


.display-block-opacity-2{
    display: flex; 
    opacity: 1;
}